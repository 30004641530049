import React from 'react';
import {Box, Button} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import RouterIcon from '@material-ui/icons/Router';
import {UserOnly} from '../providers/AppProvider';

const BUTTON_WIDTH = 'min(300px, 95%)';

const Home: React.FC = () => {
  const history = useHistory();
  const onStatusClick = () => {
    history.push('/status');
  };
  const onCallClick = () => {
    history.push('/call');
  };
  return (
    <UserOnly fallbackPath={'/account/sign-in'} display={'flex'} flexDirection={'column'} alignItems={'center'} minHeight={'calc(100vh - 48px)'}>
      <Box mt={4} width={BUTTON_WIDTH}>
        <Button variant={'contained'} fullWidth size={'large'} startIcon={<RouterIcon />} onClick={onStatusClick}>실시간 게임 현황</Button>
      </Box>
      <Box mt={2} width={BUTTON_WIDTH}>
        <Button variant={'contained'} fullWidth size={'large'} startIcon={<SettingsRemoteIcon />} onClick={onCallClick}>콜 / 헛바퀴 설정</Button>
      </Box>
    </UserOnly>
  );
};

export default Home;
