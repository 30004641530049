import React from 'react';
import {UserOnly} from '../providers/AppProvider';
import {Box, Grid, makeStyles, Typography} from '@material-ui/core';
import {StandaloneInputRef, StandaloneSelect} from '../components/StandaloneInput';
import StatusGrid from '../components/StatusGrid';
import jackpotFire from '../common/jackpot-fire';

const Status: React.FC = () => {
  const [storeId, setStoreId] = React.useState<string>();
  const [storeOptions /*, devices */] = jackpotFire.useStoreOptions(false);
  const gridClasses = useGridStyles();
  const storeRef = React.useRef<StandaloneInputRef>(null);
  React.useEffect(() => {
    if (storeOptions?.length > 0 && !storeId) {
      const newStoreId = storeOptions[0].value;
      storeRef.current?.set(newStoreId);
    }
  }, [storeOptions, storeId]);
  return (
    <UserOnly fallbackPath={'/account/sign-in'} padding={2} minHeight={'calc(100vh - 48px)'}>
      <Grid classes={gridClasses} container spacing={2}>
        <Grid item xs={2}>
          <Box mt={2}>
            <StandaloneSelect ref={storeRef} name={'storeId'} label={'매장'}  options={storeOptions} onChange={(name, value) => setStoreId(value)} />
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Typography variant={'h6'}>실시간 게임 플레이 현황</Typography>
          {storeId && <StatusGrid storeId={storeId} />}
        </Grid>
      </Grid>
    </UserOnly>
  );
};

const useGridStyles = makeStyles(() => {
  return {
    root: {
      height: '100%',
    }
  };
});

export default Status;
