import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import {useAuthState} from 'react-firebase-hooks/auth';
import {useCollection, useCollectionOnce, useDocument, useDocumentOnce} from 'react-firebase-hooks/firestore';

type ColParam = null | undefined | string | firebase.firestore.Query | firebase.firestore.CollectionReference;

type DocPath = string | null | undefined;

export type DocConverter<T> = (docSnapshot: firebase.firestore.DocumentSnapshot) => T;

export type QueryConverter<T> = (querySnapshot: firebase.firestore.QuerySnapshot) => T;

export type QuerySnapshot = firebase.firestore.QuerySnapshot;

export type DocumentSnapshot = firebase.firestore.DocumentSnapshot;

/**
 * Dependencies
 * ```bash
 * yarn add firebase react-firebase-hooks
 * ```
 */
class Fire {
  constructor(config: Object) {
    firebase.initializeApp(config);
  }
  get user() {
    return this.auth.currentUser;
  }
  get signedIn() {
    return this.user !== null;
  }
  get uid() {
    return this.user?.uid ?? '';
  }
  get auth() {
    return firebase.auth();
  }
  get firestore() {
    return firebase.firestore();
  }
  get batch () {
    return this.firestore.batch();
  }
  async signIn(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }
  async signOut() {
    return this.auth.signOut();
  }
  colRef(path: string) {
    return this.firestore.collection(path);
  }
  docRef(path: string) {
    return this.firestore.doc(path);
  }
  useAuth() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useAuthState(this.auth);
  }
  useCol(param: ColParam) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useCollection(typeof param === 'string' ? this.colRef(param) : param);
  }
  useColAsArray<T>(param: ColParam, docConverter: DocConverter<T>) {
    const [snapshot, loading, error] = this.useCol(param);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return React.useMemo(() => {
      return this.toArray([snapshot, loading, error], docConverter);
    }, [snapshot, loading, error, docConverter]);
  }
  useColAsShape<T>(param: ColParam, queryConverter: QueryConverter<T>) {
    const [snapshot, loading, error] = this.useCol(param);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return React.useMemo(() => {
      return this.toColShape([snapshot, loading, error], queryConverter);
    }, [snapshot, loading, error, queryConverter]);
  }
  useColOnce(param: ColParam) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useCollectionOnce(typeof param === 'string' ? this.colRef(param) : param);
  }
  useDoc(path: DocPath) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useDocument(path ? this.docRef(path) : null);
  }
  useDocAsShape<T>(path: DocPath, docConverter: DocConverter<T>) {
    const [snapshot, loading, error] = this.useDoc(path);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return React.useMemo(() => {
      return this.toDocShape([snapshot, loading, error], docConverter);
    }, [snapshot, loading, error, docConverter]);
  }
  useDocOnce(path: DocPath) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useDocumentOnce(path ? this.docRef(path) : null);
  }
  async add(colPath: string, data: Object) {
    return this.firestore.collection(colPath).add(data);
  }
  async set(docPath: string, data: Object) {
    return this.firestore.doc(docPath).set(data);
  }
  async edit(docPath: string, data: Object) {
    return this.firestore.doc(docPath).update(data);
  }
  async del(docPath: string) {
    return this.firestore.doc(docPath).delete();
  }
  toArray<T>(
    res: ReturnType<typeof useCollection>,
    converter: DocConverter<T>,
  ): T[] {
    const [snapshot, loading, error] = res;
    if (loading || error || !snapshot) {
      return [];
    }
    const rows: T[] = [];
    snapshot.forEach((doc) => {
      rows.push(converter(doc as DocumentSnapshot));
    });
    return rows;
  }
  toColShape<T>(
    res: ReturnType<typeof useCollection>,
    converter: QueryConverter<T>,
  ): T | null {
    const [snapshot, loading, error] = res;
    if (loading || error || !snapshot) {
      return null;
    }
    return converter(snapshot as QuerySnapshot);
  }
  toDocShape<T>(
    res: ReturnType<typeof useDocument>,
    converter: DocConverter<T>,
  ): T | null {
    const [snapshot, loading, error] = res;
    if (loading || error || !snapshot) {
      return null;
    }
    return converter(snapshot as DocumentSnapshot);
  }
}

const firebaseConfig = {
  apiKey: "AIzaSyBTe-KX0JNjMEhRGMC7FFRJX_U0GaG7efM",
  authDomain: "nosong-2995e.firebaseapp.com",
  projectId: "nosong-2995e",
  storageBucket: "nosong-2995e.appspot.com",
  messagingSenderId: "195327824811",
  appId: "1:195327824811:web:2cbb7b69704dd77cf3f8ee",
  measurementId: "G-16L9F1CN25"
};

const fire = new Fire(firebaseConfig);

export default fire;
