import {BoxProps} from '@material-ui/core';
import {AgGridReactProps} from 'ag-grid-react';
import clsx from 'clsx';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

export interface DataGridParams extends AgGridReactProps {
  containerProps: BoxProps;
}

export function useDataGrid(params: DataGridParams) {
  const {
    containerProps: {
      className: containerClassName,
      ...containerBoxProps
    },
    ...gridProps
  } = params;
  const boxProps = {
    className: clsx('ag-theme-balham-dark', containerClassName),
    ...containerBoxProps,
  };
  return {boxProps, gridProps};
}
