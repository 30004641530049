import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {AppProvider} from './providers/AppProvider';
import Header from './components/Header';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import SignIn from './pages/account/SignIn';
import Call from './pages/Call';
import Status from './pages/Status';

const App: React.FC = () => {
  return (
    <AppProvider>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <BrowserRouter>
          <Header />
          <Switch>
            <Route path={'/account/sign-in'} component={SignIn} exact={true} />
            <Route path={'/call'} component={Call} exact={true} />
            <Route path={'/status'} component={Status} exact={true} />
            <Route path={'/'} component={Home} exact={true} />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </AppProvider>
  );
};

export default App;
