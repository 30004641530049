import firebase from 'firebase/app';
import {SelectOption} from './types';

export enum CardType {
  A, _2, _3, _4, _5, _6, _7, _8, _9, _10, J, Q, K, JOKER, ST, ST_BACK, ST_ROYAL,
}

export enum ReelItemGroupType {
  normal, seven, ace, joker, straight, backStraight, royalStraight
}

export interface RemoteStore {
  storeId: string;
  storeName: string;
  devicePrefix: string;
  deviceCount: number;
  createdAt: firebase.firestore.Timestamp;
}

export interface RemoteDevice {
  deviceId: string;
  deviceName: string;
  status: string;
  probIndex: number;
  holdCount: number;
  eventActive: boolean;
  eventInfo: number;
  nextEventIndex: number;
  nextEventInfo: number;
  betAmount: number;
  betCount: number;
  betTotal: number;
  credit: number;
  win: number;
  winTotal: number;
  updatedAt: firebase.firestore.Timestamp;
}

export interface RemoteCall {
  item?: CardType;
  count?: number;
  multiply?: number;
  sudden?: boolean;
  indexDelay?: number;
  holdCount?: number;
}

export const EMAIL_DOMAIN = 'nosong.com';

export const GAME_NAME = 'jackpot';

export const ROOT_COL = `${GAME_NAME}-game`;

export const superAdmins = ['yeebwn@gmail.com', 'song@nosong.com'];

export const multiples = [1, 2, 3, 5, 7, 10, 20, 30, 50, 70, 100, 200, 300, 500, 700, 999];

export const baseScore = [1000, 2000, 3000, 5000, 10000, 15000, 20000];

export const normalCards = [
  CardType._2, CardType._3, CardType._4, CardType._5, CardType._6, CardType._8, CardType._9,
];

export const sevenCards = [
  CardType._7, CardType._10, CardType.J, CardType.Q, CardType.K,
];

export const groupLabels = [
  '일반', '세븐', '에이스 🅰️', '조커 🃏', '스트레이트 🎁', '백 스트레이트 🎉', '로얄 스트레이트 🎊'
];

export const cardCountLabels = [
  '카드 4장', '카드 5장',
];

export const suddenLabels = [
  '돌발 ⚡', '단계'
];

export const groupOptions: SelectOption[] = groupLabels.map((label, index) => ({label, value: index}));

export const cardCountOptions: SelectOption[] = cardCountLabels.map((label, index) => ({label, value: index + 4}));

export const multiplyOptions: SelectOption[] = multiples.map((multiply) => ({label: `x${multiply}`, value: multiply}));

export const suddenOptions: SelectOption[] = suddenLabels.map((label, value) => ({label, value}));
