import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import {SharedDialogProps} from '../hooks/useSharedDialog';

const DEF_OK_LABEL = '✅ 확인';
const DEF_CANCEL_LABEL = '❌ 취소';

const SharedDialog: React.FC<SharedDialogProps> = (props) => {
  const {type, open, title, message, onClose, okLabel = DEF_OK_LABEL, cancelLabel = DEF_CANCEL_LABEL} = props;
  return (
    <Dialog open={open} disableEscapeKeyDown>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      {type === 'alert' && (
        <DialogActions>
          <Button variant={'contained'} onClick={() => onClose?.()} color="primary">{okLabel}</Button>
        </DialogActions>
      )}
      {type === 'confirm' && (
        <DialogActions>
          <Button variant={'contained'} onClick={() => onClose?.(true)} color="primary">{okLabel}</Button>
          <Button variant={'contained'} onClick={() => onClose?.(false)} color="primary">{cancelLabel}</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default SharedDialog;
