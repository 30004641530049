import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import {useAuthState} from 'react-firebase-hooks/auth';
import {Box, BoxProps, CircularProgress, createMuiTheme, CssBaseline, Theme, ThemeProvider} from '@material-ui/core';
import {SnackbarProvider} from 'notistack';
import {ShareDialogOpen, useSharedDialog} from '../hooks/useSharedDialog';
import SharedDialog from '../components/SharedDialog';
import {useHistory} from 'react-router-dom';
import {superAdmins} from '../common/jackpot-types';

export interface AppContextValue {
  user: firebase.User | null;
  authError: firebase.auth.Error | undefined;
  openSharedDialog: ShareDialogOpen;
}

export interface AppProviderProps {
  theme?: Theme;
}

const defaultAppContextValue: AppContextValue = {
  user: null,
  authError: undefined,
  openSharedDialog: () => {},
};

const defaultTheme: Theme = createMuiTheme({
  palette: {
    mode: 'dark',
  }
});

const AppContext = React.createContext<AppContextValue>(defaultAppContextValue);

export const AppProvider: React.FC<AppProviderProps> = (props) => {
  const {theme = defaultTheme, children} = props;
  const [user = null, loading, authError] = useAuthState(firebase.auth());
  const [openSharedDialog, sharedDialogProps] = useSharedDialog();
  console.log('[앱프로바이더] 렌더링', {user, authLoading: loading, authError, sharedDialogProps});
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider>
        <AppContext.Provider value={{user, authError, openSharedDialog}}>
          {loading && <AuthLoading />}
          {!loading && children}
        </AppContext.Provider>
      </SnackbarProvider>
      <SharedDialog {...sharedDialogProps} />
    </ThemeProvider>
  );
};

const AuthLoading: React.FC = () => {
  console.log('[인증 시스템 로딩] 렌더링');
  return (
    <Box width={'100vw'} height={'100vh'} display={'flex'} justifyContent={'center'} marginTop={'20vh'}>
      <CircularProgress size={64} />
    </Box>
  );
};

export function useAppContext() {
  return React.useContext(AppContext);
}

export interface UserOnlyProps extends BoxProps {
  superAdminOnly?: boolean;
  fallbackPath?: string;
}

export const UserOnly: React.FC<UserOnlyProps> = ({superAdminOnly, fallbackPath, children, ...boxProps}) => {
  const {user} = useAppContext();
  const history = useHistory();
  React.useEffect(() => {
    console.log('[UserOnly] 현재 사용자', user);
    if (user) {
      if (superAdminOnly === true && !superAdmins.includes(user.uid)) {
        fallbackPath && history.replace(fallbackPath);
      }
    } else {
      fallbackPath && history.replace(fallbackPath);
    }
  }, [superAdminOnly, fallbackPath, history, user]);
  return user ? <Box {...boxProps}>{children}</Box> : null;
};

export interface GuestOnlyProps extends BoxProps {
  fallbackPath?: string;
}

export const GuestOnly: React.FC<GuestOnlyProps> = ({fallbackPath, children, ...boxProps}) => {
  const {user} = useAppContext();
  const history = useHistory();
  React.useEffect(() => {
    console.log('[GuestOnly] 현재 사용자', user);
    if (user && fallbackPath) {
      history.replace(fallbackPath);
    }
  }, [fallbackPath, history, user]);
  return user ? null : <Box {...boxProps}>{children}</Box>;
};
