import React from 'react';
import {SelectOption} from './types';
import fire, { DocumentSnapshot } from './fire';
import {
  EMAIL_DOMAIN,
  RemoteCall, RemoteDevice, RemoteStore,
  ROOT_COL, superAdmins,
} from './jackpot-types';

class JackpotFire {
  useStores(): RemoteStore[] {
    const colRef = fire.colRef(`/${ROOT_COL}`).orderBy('createdAt', 'desc');
    return fire.useColAsArray(colRef, JackpotFire.toStore);
  }
  useDevices(storeId?: string | null): RemoteDevice[] {
    if (!storeId) {
      return fire.useColAsArray(null, JackpotFire.toDevice);
    }
    const colRef = fire.colRef(`/${ROOT_COL}/${storeId}/devices/`).orderBy('deviceName');
    return fire.useColAsArray(colRef, JackpotFire.toDevice);
  }
  useStoreOptions(includeDevices?: boolean): [SelectOption[], Record<string, SelectOption[]>] {
    const colRef = fire.colRef(`/${ROOT_COL}`).orderBy('createdAt', 'desc');
    const [snapshot, loading, error] = fire.useCol(colRef);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return React.useMemo(() => {
      // 로그인한 사용자가 접근할수 있는 스토어만 필터를 함
      const remoteStores = fire.toArray([snapshot, loading, error], JackpotFire.toStore).filter((store) => {
        const storeAdminUid = `${store.devicePrefix}@${EMAIL_DOMAIN}`;
        return superAdmins.includes(fire.uid) || storeAdminUid === fire.uid;
      });
      const storeOptions: SelectOption[] = [];
      for (const store of remoteStores) {
        storeOptions.push({label: store.storeName, value: store.storeId});
      }
      if (includeDevices === true) {
        const devices: Record<string, SelectOption[]> = {};
        for (const store of remoteStores) {
          devices[store.storeId] = [];
          const {devicePrefix} = store;
          for (let i = 1; i <= store.deviceCount; ++i) {
            const serial = i.toString().padStart(3, '0');
            const deviceId =`${devicePrefix}${serial}@${EMAIL_DOMAIN}`;
            const deviceLabel = `#${serial}`;
            devices[store.storeId].push({label: deviceLabel, value: deviceId});
          }
        }
        return [storeOptions, devices];
      } else {
        return [storeOptions, {}];
      }
    }, [snapshot, loading, error, includeDevices]);
  }
  async setCall(storeId: string, deviceId: string, data: RemoteCall) {
    return fire.set(`${ROOT_COL}/${storeId}/calls/${deviceId}`, data);
  }
  async setCallAll(storeId: string, deviceIdList: string[], data: RemoteCall) {
    const batch = fire.firestore.batch();
    for (const deviceId of deviceIdList) {
      batch.set(fire.docRef(`/${ROOT_COL}/${storeId}/calls/${deviceId}`), data);
    }
    return batch.commit();
  }
  // region Converters
  static toStore(docSnapshot: DocumentSnapshot): RemoteStore {
    const {storeName, devicePrefix, deviceCount, createdAt} = docSnapshot.data() ?? {};
    return {
      storeId: docSnapshot.id,
      storeName, devicePrefix, deviceCount, createdAt
    };
  }
  static toDevice(docSnapshot: DocumentSnapshot): RemoteDevice {
    return (docSnapshot.data() ?? {}) as RemoteDevice; // FIXME
  }
  static toStoreOption(docSnapshot: DocumentSnapshot): SelectOption {
    const {storeName} = docSnapshot.data() ?? {};
    return {
      value: docSnapshot.id,
      label: storeName
    };
  }
  static toDeviceOption(docSnapshot: DocumentSnapshot): SelectOption {
    const {deviceName} = docSnapshot.data() ?? {};
    return {
      value: docSnapshot.id,
      label: deviceName
    };
  }
  // endregion
}

const jackpotFire = new JackpotFire();

export default jackpotFire;
