import React from 'react';
import {MenuItem, TextField, TextFieldProps} from '@material-ui/core';
import {AnySchema, ValidationError} from 'yup';
import {SelectOption} from '../common/types';

export interface StandaloneInputProps {
  name: string;
  label: string;
  type?: string;
  initialValue?: any;
  required?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  schema?: AnySchema;
  onChange?: (name: string, value: any) => void;
  onFocus?: TextFieldProps['onFocus'];
  onBlur?: TextFieldProps['onBlur'];
}

export interface StandaloneSelectProps extends Omit<StandaloneInputProps, 'readonly' | 'type'> {
  options: SelectOption[];
}

export interface StandaloneInputRef {
  name: string;
  value: any;
  error: ValidationError | undefined;
  set: (value: any) => void;
}

export interface StandaloneSelectRef extends Omit<StandaloneInputRef, 'error'> {}

export const StandaloneInput = React.forwardRef<StandaloneInputRef, StandaloneInputProps>((props, ref) => {
  const [value, setValue] = React.useState<any>();
  const [error, setError] = React.useState<ValidationError>();
  const {name, label, type, initialValue = '', required, readonly, disabled, schema, onChange, onFocus, onBlur} = props;
  React.useEffect(() => {
    onChange?.(name, value);
  }, [name, value, onChange]);
  React.useImperativeHandle(ref, () => ({name, value, error, set: setValue}));
  return (
    <TextField
      name={name}
      label={label}
      type={type}
      fullWidth
      size={'small'}
      value={value ?? initialValue}
      disabled={disabled}
      error={Boolean(error)}
      helperText={error?.message}
      inputProps={{readOnly: readonly}}
      onChange={({target: {value}}) => setValue(value)}
      onFocus={(e) => {
        setError(undefined);
        onFocus?.(e);
      }}
      onBlur={(e) => {
        const {target: {value}} = e;
        if (required || value) {
          schema?.validate(value).catch((error: ValidationError) => {
            setError(error);
          });
        }
        onBlur?.(e);
      }}
    />
  );
});

export const StandaloneSelect = React.forwardRef<StandaloneSelectRef, StandaloneSelectProps>((props, ref) => {
  const [value, setValue] = React.useState<any>();
  const {name, label, initialValue = '', disabled, options, onChange, onFocus, onBlur} = props;
  React.useEffect(() => {
    onChange?.(name, value);
  }, [name, value, onChange]);
  React.useImperativeHandle(ref, () => ({name, value, set: setValue}));
  return (
    <TextField
      name={name}
      label={label}
      fullWidth
      select
      size={'small'}
      value={value ?? initialValue}
      disabled={disabled}
      onChange={({target: {value}}) => {
        setValue(value);
      }}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {options.map(({label, value}, index) => <MenuItem key={`${value}-${index}`} value={value}>{label}</MenuItem>)}
    </TextField>
  );
});

export const DateTextField: React.FC<TextFieldProps> = (props) => {
  return (
    <TextField
      {...props}
      fullWidth
      size={'small'}
    />
  );
};
