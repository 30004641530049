import React, {useCallback} from 'react';
import {Box, Button, ButtonProps, Grid, makeStyles, Typography} from '@material-ui/core';
import * as yup from 'yup';
import {useAppContext, UserOnly} from '../providers/AppProvider';
import {
  StandaloneInput,
  StandaloneInputRef,
  StandaloneSelect,
  StandaloneSelectRef
} from '../components/StandaloneInput';
import jackpotFire from '../common/jackpot-fire';
import {
  baseScore,
  cardCountOptions,
  CardType,
  groupOptions,
  multiplyOptions,
  normalCards,
  ReelItemGroupType,
  suddenOptions
} from '../common/jackpot-types';

const Call: React.FC = () => {
  const [storeId, setStoreId] = React.useState<string>();
  const [deviceId, setDeviceId] = React.useState<string>();
  const [rewards, setRewards] = React.useState<number>();
  const [storeOptions, deviceOptions] = jackpotFire.useStoreOptions(true);
  const gridClasses = useGridStyles();
  const storeRef = React.useRef<StandaloneSelectRef>(null);
  const deviceRef = React.useRef<StandaloneSelectRef>(null);
  const groupRef = React.useRef<StandaloneSelectRef>(null);
  const countRef = React.useRef<StandaloneSelectRef>(null);
  const multiplyRef = React.useRef<StandaloneSelectRef>(null);
  const suddenRef = React.useRef<StandaloneSelectRef>(null);
  const delayCountRef = React.useRef<StandaloneInputRef>(null);
  const holdCountRef = React.useRef<StandaloneInputRef>(null);
  const {openSharedDialog} = useAppContext();
  React.useEffect(() => {
    if (!storeId) {
      if (storeOptions?.length === 1) {
        const newStoreId = storeOptions[0].value;
        storeRef.current?.set(newStoreId);
        deviceRef.current?.set('');
      }
    } else {
      deviceRef.current?.set('');
      groupRef.current?.set(ReelItemGroupType.normal);
      countRef.current?.set(5);
      multiplyRef.current?.set(10);
      suddenRef.current?.set(0);
      delayCountRef.current?.set(10);
      holdCountRef.current?.set(500);
    }
  }, [storeOptions, storeId]);
  const onScoreChange = useCallback((name: string, value: any) => {
    const group = groupRef.current?.value;
    const count = countRef.current?.value;
    const multiply = multiplyRef.current?.value;
    if (group >= 0 && count > 0 && multiply >= 1) {
      const score = baseScore[group];
      if (group <= ReelItemGroupType.joker) {
        setRewards(score * multiply * (count > 4 ? 2 : 1));
      } else {
        setRewards(score * multiply);
      }
    }
  }, []);
  const onCallClick: ButtonProps['onClick'] = (e) => {
    openSharedDialog('confirm', '콜 설정', `${deviceLabel}에 콜이 설정됩니다! 계속 하시겠습니까?`, async (ok) => {
      if (ok) {
        try {
          const group = parseInt(groupRef.current?.value);
          const count = parseInt(countRef.current?.value);
          const multiply = parseInt(multiplyRef.current?.value);
          const sudden = suddenRef.current?.value === 0 || suddenRef.current?.value === '0';
          const indexDelay = parseInt(delayCountRef.current?.value);
          if (storeId && deviceId && group >= 0 && count > 0 && multiply > 0 && indexDelay > 0) {
            const groupO = groupRef.current;
            const countO = countRef.current;
            const multiplyO = multiplyRef.current;
            const suddenO = suddenRef.current;
            const delayCountO = delayCountRef.current;
            await jackpotFire.setCall(storeId, deviceId, {
              item: getItemFromGroup(group),
              count, multiply, sudden, indexDelay,
            });
            groupO?.set(ReelItemGroupType.normal);
            countO?.set(5);
            multiplyO?.set(10);
            suddenO?.set(0);
            delayCountO?.set(10);
          }
        } catch (error) {
          // TODO: 여기서 오류를 처리해야 함!
          console.log(error);
        }
      }
    });
  };
  const onHoldCountClick: ButtonProps['onClick'] = () => {
    openSharedDialog('confirm', '헛바퀴 설정', `${deviceLabel}에 헛바퀴가 설정됩니다! 계속 하시겠습니까?`, async (ok) => {
      if (ok) {
        try {
          const holdCount = parseInt(holdCountRef.current?.value);
          if (storeId && deviceId && holdCount > 0) {
            const holdCountO = holdCountRef.current;
            await jackpotFire.setCall(storeId, deviceId, {holdCount});
            holdCountO?.set(500);
          }
        } catch (error) {
          // TODO: 여기서 오류를 처리해야 함!
          console.log(error);
        }
      }
    });
  };
  const deviceLabelVal = deviceOptions[storeId ?? '']?.find((i: any) => i.value === deviceId)?.label ?? '';
  const deviceLabel = deviceId ? `기기${deviceLabelVal}` : '기기#???';
  return (
    <UserOnly fallbackPath={'/account/sign-in'} padding={2} minHeight={'calc(100vh - 48px)'}>
      <Grid classes={gridClasses} container spacing={2}>
        <Grid item xs={3}>
          <Typography variant={'h6'}>기기 선택</Typography>
          <Box mt={2}>
            <StandaloneSelect ref={storeRef} name={'storeId'} label={'매장'} options={storeOptions} onChange={(name, value) => setStoreId(value)} />
          </Box>
          <Box mt={2}>
            <StandaloneSelect ref={deviceRef} name={'deviceId'} label={'설정할 기기'} options={deviceOptions[storeId ?? ''] ?? []} onChange={(name, value) => setDeviceId(value)} />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h6'}>콜 설정</Typography>
          <Box mt={2}>
            <StandaloneSelect ref={groupRef} options={groupOptions} name={'group'} label={'이벤트 아이템 선택'} onChange={onScoreChange} />
          </Box>
          <Box mt={2}>
            <StandaloneSelect ref={countRef} options={cardCountOptions} name={'count'} label={'카드 갯수 선택'} onChange={onScoreChange} />
          </Box>
          <Box mt={2}>
            <StandaloneSelect ref={multiplyRef} options={multiplyOptions} name={'multiply'} label={'배당 선택'} onChange={onScoreChange} />
          </Box>
          <Box mt={2}>
            <StandaloneSelect ref={suddenRef} options={suddenOptions} name={'sudden'} label={'단계 / 돌발 선택'} />
          </Box>
          <Box mt={2}>
            <StandaloneInput ref={delayCountRef} name={'delayCount'} label={'몇바퀴 후 이벤트 실행'} required={true} schema={delayCountSchema} />
          </Box>
          <Box mt={2}>
            보상 : {formatCurrency(rewards)}
          </Box>
          <Box mt={2}>
            <Button variant={'contained'} size={'large'} onClick={onCallClick} disabled={!deviceId}>💵 콜({deviceLabel}) 설정</Button>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h6'}>헛바퀴 설정</Typography>
          <Box mt={2}>
            <StandaloneInput ref={holdCountRef} name={'holdCount'} label={'설정할 헛바퀴수'} required={true} schema={delayCountSchema} />
          </Box>
          <Box mt={2}>
            <Button variant={'contained'} size={'large'} onClick={onHoldCountClick} disabled={!deviceId}>🛠 헛바퀴({deviceLabel}) 설정</Button>
          </Box>
        </Grid>
      </Grid>
    </UserOnly>
  );
};

function formatCurrency(amount: any) {
  return amount && amount >= 10000 ? `${amount / 10000}만` : amount;
}

function getRandomItem<T>(items: T[]): T {
  return items[Math.floor(Math.random() * items.length)];
}

function getItemFromGroup(group: number | string): CardType {
  if (typeof group === 'string') {
    group = parseInt(group);
  }
  switch (group) {
    case 0: return CardType._2; //getRandomItem(normalCards);
    case 1: return CardType._7; //getRandomItem(sevenCards);
    case 2: return CardType.A;
    case 3: return CardType.JOKER;
    case 4: return CardType.ST;
    case 5: return CardType.ST_BACK;
    case 6: return CardType.ST_ROYAL;
    default: return getRandomItem(normalCards);
  }
}

const delayCountSchema = yup.number()
  .typeError('숫자를 입력해 주세요.')
  .min(1, '양의 정수를 입력해 주세요.');

const useGridStyles = makeStyles(() => {
  return {
    root: {
      height: '100%',
    }
  };
});

export default Call;
