import React, {MouseEventHandler} from 'react';
import {AppBar, Box, Button, makeStyles, Toolbar, Typography} from '@material-ui/core';
import SignOutIcon from '@material-ui/icons/ExitToApp';
import {useHistory} from 'react-router-dom';
import {useAppContext} from '../providers/AppProvider';
import fire from '../common/fire';

const Header: React.FC = () => {
  const {user} = useAppContext();
  const history = useHistory();
  const classes = useStyles();
  const onLogoutClick = async () => {
    await fire.signOut();
  };
  const onTitleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    history.push('/');
  };
  return (
    <AppBar position={'static'}>
      <Toolbar variant={'dense'}>
        <Typography className={classes.title} variant={'h6'} color={'inherit'}>대박 관리도구</Typography>
        {user && (
          <Box mr={2}>
            <Button color={'inherit'} size={'small'} onClick={onTitleClick}>🏠 Home</Button>
          </Box>
        )}
        {user && <Button variant={'outlined'} color={'inherit'} size={'small'} startIcon={<SignOutIcon />} onClick={onLogoutClick}>로그아웃</Button>}
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles({
  title: {
    flexGrow: 1,
  },
});

export default Header;
